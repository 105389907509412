body {
  font-family: "Inter", sans-serif !important;
}

.card {
  overflow: hidden;
}

/* base style for store card */
[class*="card-store"] p {
  margin-bottom: 0;
}
[class*="card-store"] a.title {
  display: block;
  text-decoration: none;
}
[class*="card-store"] a.title:hover {
  color: #0d6efd;
}
[class*="card-store"] .price {
  color: #212529;
}
[class*="card-store"] .price-old {
  color: #9da1a7;
  margin-left: 3px;
  font-size: 90%;
}
[class*="card-store"] .img-wrap {
  position: relative;
}
[class*="card-store"] .img-wrap img {
  height: 100%;
  max-width: 100%;
  width: auto;
}

/* store card list view style */

.card-store-list .title {
  color: #212529;
}
.card-store-list .img-wrap {
  height: 100px;
  padding: 0.5rem;
}
.card-store-list .info-aside {
  padding: 1.25rem 1.25rem;
  border-left: 1px solid #dee2e6;
  height: 100%;
}

/* style for store profile */
.store .img-wrap img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.store .img-wrap {
  height: 150px;
}

.limit-2lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.limit-4lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.img-div {
  height: 100px;
  display: flex;
  justify-content: center;
}

.img-div img {
  height: 100%;
  max-width: 100%;
  width: auto;
}

.specs-row .spec-col > div .spec {
  font-weight: bold;
  font-size: 15px;
}

.specs-row .spec-col > div .value {
  font-size: 14px;
  margin-top: -4px;
  margin-left: 0;
}
.desc > p {
  font-family: Whitney, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif;
}

a {
  text-decoration: none;
}

/* TOM TOM  */

.map-icon {
  background-size: cover;
  height: 30px;
  width: 30px;
}
.route-marker {
  align-items: center;
  background-color: #4a90e2;
  border: solid 3px #2faaff;
  border-radius: 50%;
  display: flex;
  height: 32px;
  justify-content: center;
  transition: width 0.1s, height 0.1s;
  width: 32px;
}

.category-dropdown{
  position: relative;
  max-height: 300px;
}