.mobile-block {
  display: none;
}

@media (max-width: 1200px) {
  /* tablet devices */
}
@media (max-width: 992px) {
  /* small tablet devices */
  .card-product-grid .img-wrap {
    height: 200px;
  }

  .card-product-list {
    margin-bottom: 20px;
  }
  .card-product-list .img-wrap {
    border-bottom: 1px solid #dee2e6;
  }
}
@media all and (max-width: 768px) {
  /* mobile devices */
  .section-header .logo {
    max-height: 36px;
    width: auto;
  }

  .navbar-expand .navbar-collapse {
    flex-direction: column;
  }

  .gallery-wrap .thumbs-wrap {
    margin-bottom: 1rem;
    white-space: nowrap;
    overflow-x: auto;
  }

  .mobile-order-first {
    order: -1;
  }

  .mobile-order-1 {
    order: 1;
  }

  .mobile-order-2 {
    order: 2;
  }

  .mobile-order-3 {
    order: 3;
  }

  .mobile-py {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .mobile-pb {
    padding-bottom: 20px;
  }

  .mobile-pt {
    padding-top: 20px;
  }
}
